import React from 'react';
import axios from 'axios';

function Emailer(){

const abc=()=>{
    axios({
        method: 'POST',
//        url: 'https://cors-anywhere.herokuapp.com/https://pdf22fou98.execute-api.eu-west-2.amazonaws.com/default/LambdaEmailer',
        url: 'https://pdf22fou98.execute-api.eu-west-2.amazonaws.com/default/LambdaEmailer',
        data:JSON.parse('{\n' +
            '    "from":"Value",\n' +
            '    "Code":"Value1"\n' +
            '}'),
        headers: {
            'Pragma': 'no-cache','Content-Type': 'application/json; charset=utf-8',
            'x-api-key':process.env.REACT_APP_API_KEY
           // 'Access-Control-Allow-Origin': 'http://localhost:3000'
        }
    }).then(function (response) {
        //alert("Found Person Interview record was updated successfully");
        console.log(response);
        //_this.handleCancel();
    }).catch(error => {
        console.log(error.response);
    });
}
        return(
            <div onClick={abc}>Email</div>
        );

    }
export default Emailer;