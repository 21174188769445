import React , {Component}  from 'react';
import './App.css';
import logo from './images/logo.jpg';
import Emailer from "./Emailer";


class App extends Component {

    render() {
        document.title = 'Software Consultant Ltd';

        return (
            <div className="App">
                <header className="App-header">
                    <img className="logopic" src={logo}  alt="logo" />

                    <p>
                        Welcome to Software Consultant Limited. </p>

                    <p> Contact us at: admin@softwarecl.co.uk</p>

                    <Emailer/>
                    <a href="https://scl.appointlet.com/b/software" target="_blank" rel="noopener noreferrer" > Book a Virtual Catchup</a>
                </header>
            </div>
        );
    }
}
export default App;
